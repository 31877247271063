import React, { useEffect, useState } from "react";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import "./Chart.css";
import { io } from "socket.io-client";

const socket = io("https://socket.rubyexplorer.com");

// ✅ Set default chart styles
defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";

const TransactionsPerHour = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // ✅ Emit the event to request transaction data
    socket.emit("getTransactionGraph");

    // ✅ Listen for incoming updates
    const handleTransactionUpdate = (data) => {
      setTransactionData(data.TransactionGraph || []);
      setLoading(false); // ✅ Stop loading when data is received
    };

    socket.on("getTransactionGraph-update", handleTransactionUpdate);

    // ✅ Cleanup on unmount
    return () => {
      socket.off("getTransactionGraph-update", handleTransactionUpdate);
    };
  }, []);

  return (
    <div className="TransactionsandfeePerHour">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="TransactionsandfeePerHour-dataCard TransactionsandfeePerHour-revenueCard">
          <Line
            data={{
              labels: transactionData.map((data) => `Hour ${data.hour}`),
              datasets: [
                {
                  label: "Transaction Count",
                  data: transactionData.map((data) => data.count),
                  backgroundColor: "rgba(6, 79, 240, 0.5)",
                  borderColor: "#064FF0",
                  yAxisID: "y-axis-transactions",
                },
                {
                  label: "Total Fee",
                  data: transactionData.map((data) => data.totalFee),
                  backgroundColor: "rgba(255, 48, 48, 0.5)",
                  borderColor: "#FF3030",
                  yAxisID: "y-axis-fees",
                },
              ],
            }}
            options={{
              elements: {
                line: {
                  tension: 0.5,
                },
              },
              scales: {
                "y-axis-transactions": {
                  type: "linear",
                  position: "left",
                  title: {
                    display: true,
                    text: "Transaction Count",
                  },
                },
                "y-axis-fees": {
                  type: "linear",
                  position: "right",
                  title: {
                    display: true,
                    text: "Total Fee",
                  },
                  grid: {
                    drawOnChartArea: false, // Avoid overlap with left Y-axis
                  },
                },
              },
              plugins: {
                title: {
                  text: "Transaction Count & Fees per Hour",
                  display: true,
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TransactionsPerHour;
