import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col } from "react-bootstrap";
import transactionlogo from "../images/smart-contract.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContractDeploychart from './Charts/ContractDeploychart';

const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`https://rubyexplorer.com/getContractdeploy`);
        const data = await response.json();
        setTransactions(data.tokendepoloy);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTransactions();
  }, []);



  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-6)}`;
  };
  const handleTransactionClick = (hash) => {
    navigate(`/contractDetail/${hash}`);
  };

  const handleallTransactionClick = () => {
    navigate(`/VerifiedRuby`);
  };


  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    toast.success("copied to clipboard!");
  };
  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };
  return (
    <>
      <div>

        <Row className="transaction-container">
          
          <Col md={7}>
            <h4 className="headingTransactions">Latest Contract</h4>
            <div className="transaction-body">
              <Table table>
                <thead>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  {transactions.slice(0, 8).map((transaction, index) => (
                    <tr key={index}>
                      <td>


                        <div >
                          <span className='transaction-hash' onClick={() => handleTransactionClick(transaction.contract_address)} style={{ cursor: "pointer" }}>
                            <img src={transactionlogo} alt="block logo" className="transactionlogo" />
                            {shortenAddress(6, transaction.contract_address)}
                          </span>
                          <span onClick={() => copyToClipboard(transaction.contract_address)} className="copy-icon" title="Copy From">&#x2398;</span>

                        </div>


                      </td>

                      <td>
                        <div >
                          <span className='transaction-hash'>
                            {transaction.contractname}
                          </span>

                        </div>
                      </td>

                     
                      <td>
                        <div >

                          <span className='transaction-time'>
                            {formatTimeAgo(transaction.createdAt)}
                          </span>
                        </div>
                      </td>

                    </tr>
                  ))}
                  <hr style={{ margin: "20px 0", color: "lightblue" }} />{" "}

                  <div className="alltransactions" onClick={handleallTransactionClick}>
                    View all Contract
                  </div>
                </tbody>
              </Table>

            </div>

            <div className="Topaccounts-cards">
              <Table table>
                <tbody>
                  {transactions.slice(0, 5).map((transaction, index) => (
                    <tr key={transaction.contract_address} style={{ cursor: "pointer" }}>
                      <td>
                        <div>
                          <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />

                          <div className='Topaccounts-address-mobile ' onClick={() => handleTransactionClick(transaction.contract_address)}>{shortenAddress(15, transaction.contract_address)}
                            <span onClick={() => copyToClipboard(transaction.contract_address)} className="copy-icon" title="Copy From">&#x2398;</span>
                          </div>
                          <br />
                          <div className="Topaccounts-data-mobile">Name {transaction.contractname}</div>
                         
                        </div>
                      </td>
                      <tr>   <span className='Topaccounts-time'>
                        {formatTimeAgo(transaction.createdAt)}
                      </span></tr>
                      <hr style={{ margin: "20px 0", color: "lightblue" }} />{" "}
                    </tr>
                  ))}
                  <div className="alltransactions" onClick={handleallTransactionClick}>
                    View all Contract
                  </div>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col>
            <ContractDeploychart />
          </Col>
        </Row>


      </div>

    </>
  );
};

export default TransactionTable;
