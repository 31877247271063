import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode'; // Corrected import for jwtDecode
import '../Styles/Dashboard.css';
import usdtlogo from '../images/usdt.png';
import rupeeindian from '../images/rupee-indian.png';
import logo from '../images/balancelogo.png';
import addresspng from "../images/address.png";
import transactionlogo from "../images/transaction.png";
import addresspng1 from "../images/address1.png";
import arrow from "../images/arrow.png";
import success from "../images/success.svg";
import QRCode from 'qrcode.react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Table, Row, Col } from "react-bootstrap";
import smartcontract from '../images/smart-contract.png';
import tokenlogo from '../images/tokenslogo.png';
import SendModal from './SendModal';
import SearchToken from './SearchToken';
import Selectetoken from './selecttoken';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Dashboard = () => {
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [walletData, setWalletData] = useState(null);
    const [rubyprice, setRubyprice] = useState('');
    const [activeTab, setActiveTab] = useState("Recent Transactions");
    const [transactions, setTransactions] = useState([]);
    const [showQRCode, setShowQRCode] = useState(false);
    const [qrCodeAddress, setQRCodeAddress] = useState('');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [recipientAddress, setRecipientAddress] = useState('');
    const [sendAmount, setSendAmount] = useState('');
    const [privateKey, setPrivateKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [tokentransactions, setTokenTransactions] = useState([]);
    const [tokendata, setToken] = useState([]);

    const [showtokenModal, setShowtokenModal] = useState(false);
    const [Contractaddress, setContractforsend] = useState('');
    const [Internaltransactions, setInternalTransactions] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("");
    const [selected, setSelected] = useState("");
    const [pagenumber, setPagenumber] = useState(1);
    const [totalTransction, setTotalTransction] = useState(0);
    const pagelength = 20; // Default page length

    const redirectToLogin = useCallback(() => {
        navigate(`/Account`);
    }, [navigate]);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success("Address copied to clipboard!");
            setTimeout(() => setMessage(''), 2000);
        });
    };

    useEffect(() => {
        const fetchWalletData = async () => {
            try {
                const response = await fetch(
                    `https://rubyexplorer.com/getwalletdata/${email}`
                );
                const data = await response.json();
                if (data.status) {
                    setWalletData(data.result);
                } else {
                    redirectToLogin();
                }
            } catch (error) {
                console.error(error);
                redirectToLogin();
            }
        };
        if (email) {
            fetchWalletData();
        }
    }, [email, redirectToLogin]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            redirectToLogin();
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp * 1000 > Date.now()) {
                setEmail(decodedToken.email);
            } else {
                redirectToLogin();
            }
        }
    }, [redirectToLogin]);

    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const response = await fetch('https://rubyexplorer.com/getrubyprice');
                const data = await response.json();
                setRubyprice(data.result);
            } catch (error) {
                console.error(error);
            }
        };
        fetchPrice();
    }, []);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                if (walletData && walletData.address) {
                    setSelectedAddress(walletData.address)
                    const response = await fetch(
                        `https://rubyexplorer.com/getuserTransction/${walletData.address}/${pagenumber}/${pagelength}`
                    );
                    const data = await response.json();
                    setTransactions(data.result || []);
                    setTotalTransction(data.trx_length); // Ensures correct total count

                }
            } catch (error) {
                console.error(error);
            }
        };

        if (activeTab === "Recent Transactions" && walletData) {
            fetchTransactions();
        }
    }, [activeTab, walletData, pagenumber]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= Math.ceil(totalTransction)) {
            setPagenumber(newPage);
        }
    };
    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://rubyexplorer.com/getallTokens/${walletData.address}`);
                const data = await response.json();

                setToken(data.result ? data.result : []); // Corrected to map the array properly
            } catch (error) {
                console.error('Error fetching all transactions:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchTransactions();
    }, [walletData?.address]);



    // Function to fetch token transactions
    const fetchTokenTransactions = async (contractAddress) => {
        if (!walletData?.address || !contractAddress) return;

        setLoading(true);
        try {
            const response = await fetch(`https://rubyexplorer.com/getoneTokenTransction/${walletData.address}/${contractAddress}/${pagenumber}/${pagelength}`);
            const data = await response.json();
            setTokenTransactions(data.result ? data.result : []);
            setTotalTransction(data.trx_length); // Ensures correct total count

        } catch (error) {
            console.error("Error fetching all transactions:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePagtokeneChange = (newPage) => {
        if (newPage > 0 && newPage <= Math.ceil(totalTransction)) {
            setPagenumber(newPage);
            fetchTokenTransactions(selected.contractAddress);
        }
    };
    // Fetch transactions when user selects a token or changes the selected token
    useEffect(() => {
        if (selected?.contractAddress) {
            fetchTokenTransactions(selected.contractAddress); // Fetch transactions whenever contractAddress changes
        }
    }, [selected?.contractAddress]); // Watch for changes in contractAddress specifically


    const shortenAddress = (length, address) => {
        return `${address.slice(0, length)}...${address.slice(-4)}`;
    };
    const handleaddressClick = (address) => {
        navigate(`/address/${address}`);
    };
    const formatTimeAgo = (date) => {
        const dates = new Date(date).getTime();
        const dateInSeconds = Math.floor(dates / 1000);
        let currentTime = Math.floor(Date.now() / 1000);
        let second = currentTime - dateInSeconds;
        if (second < 60) {
            return `${second}s ago`;
        } else if (second < 3600) {
            let minutes = Math.floor(second / 60);
            return `${minutes}m ago`;
        } else if (second < 86400) {
            let hours = Math.floor(second / 3600);
            return `${hours}h ago`;
        } else {
            let days = Math.floor(second / 86400);
            return `${days}d ago`;
        }
    };

    const handleReceiveClick = () => {
        if (walletData && walletData.address) {
            setQRCodeAddress(walletData.address);
            setShowQRCode(true);
            setShowQRModal(true);  // Show the QR code modal
        }
    };

    const handlecontractDetailClick = (contractAddress) => {
        navigate(`/TokenDetail/${contractAddress}`);
    };


    const copyToClipboard = (content) => {
        navigator.clipboard.writeText(content);
        toast.success("Contract copied to clipboard!");

    };

    const trimValue = (value) => {
        let trimmedValue = Number(value).toFixed(5);
        trimmedValue = parseFloat(trimmedValue).toString();
        return trimmedValue;
    };



    const handleSend = async () => {
        const url = `https://rubyexplorer.com/send/${privateKey}/${recipientAddress}/${sendAmount}`;
        setLoading(true);
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                if (data.status) {
                    toast.success("Transaction successful");

                    navigate(`/Dashboard`);
                } else {
                    toast.error("Transaction failed");
                }
            } else {
                toast.error("Transaction failed");

            }
        } catch (error) {
            toast.error("Transaction failed");

        } finally {
            setLoading(false);
            setShowModal(false);  // Close the send modal
        }
    };
    const Contractforsend = (contract) => {
        setContractforsend(contract);
    };

    const handleClose = () => setShowtokenModal(false);
    const handleShow = () => setShowtokenModal(true);
    const handleTokenSend = async (body) => {
        const url = `https://rubyexplorer.com/sendtoken/${body.privateKey}/${body.address}/${Contractaddress}/${body.amount}`;
        setLoading(true);
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                if (data.status) {
                    toast.success("Token Transaction successful");
                    navigate(`/Dashboard`);
                } else {
                    toast.error("Transaction failed");
                }
            } else {
                toast.error("Transaction failed");

            }
        } catch (error) {
            toast.error("Transaction failed");
        } finally {
            setLoading(false);
            setShowModal(false);  // Close the send modal
        }
    };

    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://rubyexplorer.com/getinternaltrx/${walletData.address}/1/200`);
                const data = await response.json();

                setInternalTransactions(data.result ? data.result : []); // Corrected to map the array properly
            } catch (error) {
                console.error('Error fetching all transactions:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchTransactions();
    }, [walletData?.address]);

    const handleTransactionClick = (hash) => {
        navigate(`/transaction/${hash}`);
    };

    const handleAlladdressClick = (address) => {
        navigate(`/address/${address}`);
    };


    return (
        <div>
            {message && <div className="alert alert-info mt-3">{message}</div>}
            <div className='dashboard-heading'>Overview</div>
            <div className="dashboard-card">
                <div className='dashboard-text'>Address</div>
                <div className='dashboard-address-container'>
                    <div
                        className='dashboard-address'
                        onClick={() => handleCopy(walletData?.address)}
                        title="Click to copy"
                    >
                        <strong>{walletData ? walletData.address : 'Loading...'}</strong>
                    </div>
                    <div
                        className='dashboard-address1'
                        onClick={() => handleCopy(walletData?.address)}
                        title="Click to copy"
                    >
                        <strong>{walletData ? shortenAddress(16, walletData.address) : 'Loading...'}</strong>
                    </div>
                    <button className="copy-button" onClick={() => handleCopy(walletData?.address)}>Copy</button>
                    <button className="receive-button" onClick={handleReceiveClick}>Receive</button>
                    <button className="send-button" onClick={() => setShowModal(true)}>Send</button>
                </div>
                <div className='dashboard-text'>Balance</div>
                <div className='dashboard-balance'>
                    <img src={logo} alt="usdtlogo" className="rubybalancelogo" />
                    <strong>{walletData ? trimValue(walletData.balance) : 'Loading...'}</strong>
                </div>
                {showQRCode && (
                    <Modal show={showQRModal} onHide={() => setShowQRModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>QR Code</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="qr-code-container">
                                <QRCode value={qrCodeAddress} size={256} />
                            </div>

                        </Modal.Body>


                        <div style={{ textAlign: "center" }}><button className="addresscopy-button" onClick={() => handleCopy(walletData?.address)}> {(walletData.address)} Copy</button></div>                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowQRModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                )}
            </div>

            <div className='value-card'>
                <div className="dashboard-card-usdt">
                    <div className='dashboard-usdt-text'><strong>US Dollar</strong></div>
                    <div className='dashboard-usdt-balance'>
                        <img src={usdtlogo} alt="usdtlogo" className="usdtlogo" />
                        <strong>{walletData && rubyprice.price ? (rubyprice.price * walletData.balance).toFixed(2) : 0}</strong>
                    </div>
                </div>
                <div className="dashboard-card-inr">
                    <div className='dashboard-usdt-text'><strong>Indian Rupee</strong></div>
                    <div className='dashboard-usdt-balance'>
                        <img src={rupeeindian} alt="usdtlogo" className="inrlogo" />
                        <strong>{walletData ? (87 * rubyprice.price * walletData.balance).toFixed(2) : 0}</strong>
                    </div>
                </div>
            </div>




            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Ruby</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div>Transaction initiated please wait a few seconds...</div>
                    ) : (
                        <>
                            <Form.Group controlId="recipientAddress">
                                <Form.Label>Recipient Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter recipient address"
                                    value={recipientAddress}
                                    onChange={(e) => setRecipientAddress(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="sendAmount">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter amount to send"
                                    value={sendAmount}
                                    onChange={(e) => setSendAmount(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="privateKey">
                                <Form.Label>Private Key</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your private key"
                                    value={privateKey}
                                    onChange={(e) => setPrivateKey(e.target.value)}
                                />
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {!loading && (
                        <>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSend}>
                                Send
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>




            <div className="dashbord-tabs">
                {["Recent Transactions", "Tokens", "Token Transactions"].map(
                    (tab) => (
                        <button
                            key={tab}
                            className={`dashbord-button ${activeTab === tab ? "active" : ""}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </button>
                    )
                )}
            </div>

            {activeTab === "Recent Transactions" && (
                <div className="latesttransaction-table-body">
                    <div className="pagination-controls"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <Button
                            variant="primary"
                            disabled={pagenumber === 1}
                            onClick={() => handlePageChange(pagenumber - 1)}
                            style={{
                                padding: "4px 8px",
                                fontSize: "12px",
                                borderRadius: "4px",
                                backgroundColor: "#007bff",
                                border: "none",
                                width: "auto",  // Adjusts width based on text
                                minWidth: "60px"
                            }}
                        >
                            Prev
                        </Button>

                        <span className="pagination-info"
                            style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                textAlign: "center",
                                minWidth: "80px"
                            }}>
                            Page {pagenumber} of {Math.ceil(totalTransction / pagelength)}
                        </span>

                        <Button
                            variant="primary"
                            disabled={pagenumber === Math.ceil(totalTransction / pagelength)}
                            onClick={() => handlePageChange(pagenumber + 1)}
                            style={{
                                padding: "4px 8px",
                                fontSize: "12px",
                                borderRadius: "4px",
                                backgroundColor: "#007bff",
                                border: "none",
                                width: "auto",  // Adjusts width based on text
                                minWidth: "60px"
                            }}
                        >
                            Next
                        </Button>
                    </div>

                    <Table>
                        <tbody>
                            {transactions.map((latesttransaction, index) => (
                                <tr key={index}>
                                    {/* Column 1: Transaction type and hash */}
                                    <td>
                                        <div className="latesttransaction-category">
                                            <span>
                                                {latesttransaction.transctiontype === 0
                                                    ? "Coin Transfer"
                                                    : latesttransaction.transctiontype === 1
                                                        ? "Contract Create"
                                                        : latesttransaction.transctiontype === 2
                                                            ? "Token Transfer"
                                                            : "Contract Call"}
                                            </span>
                                            <span className="latesttransaction-time">
                                                {formatTimeAgo(latesttransaction.createdAt)}

                                            </span>
                                        </div>

                                        <div
                                            className="latesttransaction-sender"
                                            onClick={() => handleTransactionClick(latesttransaction.hash)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                src={transactionlogo}
                                                alt="From"
                                                className="latesttransaction-address-icon"
                                            />
                                            {shortenAddress(6, latesttransaction.hash)}
                                            {/* Copy icon for "from" address */}
                                            <span
                                                className="latesttransaction-copy-icon"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    copyToClipboard(latesttransaction.hash);
                                                }}
                                            >
                                                &#x2398;
                                            </span>
                                        </div>



                                    </td>

                                    {/* Column 2: Sender (from) and Receiver (to) */}
                                    <td>
                                        <div className="latesttransaction-sender"
                                            onClick={() => handleAlladdressClick(latesttransaction.from)}
                                            style={{ cursor: "pointer" }}>
                                            <img
                                                src={addresspng}
                                                alt="From"
                                                className="latesttransaction-address-icon"
                                            />
                                            {shortenAddress(6, latesttransaction.from)}
                                            {/* Copy icon for "from" address */}
                                            <span
                                                className="latesttransaction-copy-icon"
                                                onClick={() => copyToClipboard(latesttransaction.from)}
                                            >
                                                &#x2398;
                                            </span>
                                        </div>
                                        <div className="latesttransaction-receiver"
                                            onClick={() => handleAlladdressClick(latesttransaction.to)}
                                            style={{ cursor: "pointer" }}>
                                            <img
                                                src={addresspng1}
                                                alt="To"
                                                className="latesttransaction-address-icon-alt"
                                            />
                                            {shortenAddress(6, latesttransaction.to)}
                                            {/* Copy icon for "to" address */}
                                            <span
                                                className="latesttransaction-copy-icon"
                                                onClick={() => copyToClipboard(latesttransaction.to)}
                                            >
                                                &#x2398;
                                            </span>
                                        </div>
                                    </td>

                                    {/* Column 3: Time and status */}
                                    <td>
                                        <div className="latesttransaction-status success">
                                            Success
                                        </div>
                                        <div >
                                            <div className='latesttransaction-status SendReceived'> {walletData?.address === latesttransaction.from ? 'Send' : 'Received'}</div>

                                        </div>

                                    </td>
                                    {/* Column 4: Value and Fee */}
                                    <td>
                                        <div className="latesttransaction-value">
                                            {latesttransaction.value} Value
                                        </div>
                                        <div className="latesttransaction-fee">
                                            {latesttransaction.fee} Fee
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </div>
            )}
            {activeTab === "Tokens" && (
                <div>
                    <SearchToken onSelectAddress={selectedAddress} />

                    <div className="latesttransaction-table-body">
                        <Table>
                            <tbody>
                                {tokendata.map((latesttransaction, index) => (
                                    <tr key={index}>
                                        {/* Column 1: Transaction type and hash */}
                                        <td>


                                            <div
                                                className="latesttransaction-category"
                                                onClick={() => handlecontractDetailClick(latesttransaction.contractAddress)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img
                                                    src={smartcontract}
                                                    alt="From"
                                                    className="latesttransaction-address-icon"
                                                />
                                                {shortenAddress(10, latesttransaction.contractAddress)}
                                                {/* Copy icon for "from" address */}
                                                <span
                                                    className="latesttransaction-copy-icon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        copyToClipboard(latesttransaction.contractAddress);
                                                    }}
                                                >
                                                    &#x2398;
                                                </span>
                                            </div>



                                        </td>
                                        <td>

                                            <div >
                                                <div className='latesttransaction-status SendReceived'>  {latesttransaction.name}</div>

                                            </div>

                                        </td>
                                        <td>
                                            <div >
                                                <div className='latesttransaction-status success'> Balance- {latesttransaction.balance}</div>

                                            </div>

                                        </td>
                                        <td><div>
                                            <span
                                                className="dashboard-send"
                                                onClick={() => {
                                                    Contractforsend(latesttransaction.contractAddress);
                                                    handleShow();
                                                }}
                                            >
                                                Send
                                            </span>
                                            <span className="dashboard-receive" onClick={handleReceiveClick}>
                                                Receive
                                            </span>
                                        </div></td>
                                        {/* Column 4: Value and Fee */}

                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </div></div>

            )}
            {activeTab === "Token Transactions" && (
                <div>
                    <Selectetoken address={walletData?.address} selected={selected} setSelected={setSelected} />

                    {selected && (

                        <div className="latesttransaction-table-body">
                              <div className="pagination-controls"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            marginTop: "10px"
                        }}
                    >
                        <Button
                            variant="primary"
                            disabled={pagenumber === 1}
                            onClick={() => handlePagtokeneChange(pagenumber - 1)}
                            style={{
                                padding: "4px 8px",
                                fontSize: "12px",
                                borderRadius: "4px",
                                backgroundColor: "#007bff",
                                border: "none",
                                width: "auto",  // Adjusts width based on text
                                minWidth: "60px"
                            }}
                        >
                            Prev
                        </Button>

                        <span className="pagination-info"
                            style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                textAlign: "center",
                                minWidth: "80px"
                            }}>
                            Page {pagenumber} of {Math.ceil(totalTransction / pagelength)}
                        </span>

                        <Button
                            variant="primary"
                            disabled={pagenumber === Math.ceil(totalTransction / pagelength)}
                            onClick={() => handlePagtokeneChange(pagenumber + 1)}
                            style={{
                                padding: "4px 8px",
                                fontSize: "12px",
                                borderRadius: "4px",
                                backgroundColor: "#007bff",
                                border: "none",
                                width: "auto",  // Adjusts width based on text
                                minWidth: "60px"
                            }}
                        >
                            Next
                        </Button>
                    </div>
                            <Table>
                                <tbody>
                                    {tokentransactions.map((latesttransaction, index) => (
                                        <tr key={index}>
                                            {/* Column 1: Transaction type and hash */}
                                            <td>
                                                <div className="latesttransaction-category">
                                                    <span>
                                                        {latesttransaction.transctiontype === 0
                                                            ? "Coin Transfer"
                                                            : latesttransaction.transctiontype === 1
                                                                ? "Contract Create"
                                                                : latesttransaction.transctiontype === 2
                                                                    ? "Token Transfer"
                                                                    : "Contract Call"}
                                                    </span>
                                                    <span className="latesttransaction-time">
                                                        {formatTimeAgo(latesttransaction.createdAt)}

                                                    </span>
                                                </div>

                                                <div
                                                    className="latesttransaction-sender"
                                                    onClick={() => handleTransactionClick(latesttransaction.hash)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <img
                                                        src={transactionlogo}
                                                        alt="From"
                                                        className="latesttransaction-address-icon"
                                                    />
                                                    {shortenAddress(6, latesttransaction.hash)}
                                                    {/* Copy icon for "from" address */}
                                                    <span
                                                        className="latesttransaction-copy-icon"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            copyToClipboard(latesttransaction.hash);
                                                        }}
                                                    >
                                                        &#x2398;
                                                    </span>
                                                </div>



                                            </td>

                                            {/* Column 2: Sender (from) and Receiver (to) */}
                                            <td>
                                                <div className="latesttransaction-sender"
                                                    onClick={() => handleAlladdressClick(latesttransaction.from)}
                                                    style={{ cursor: "pointer" }}>
                                                    <img
                                                        src={addresspng}
                                                        alt="From"
                                                        className="latesttransaction-address-icon"
                                                    />
                                                    {shortenAddress(6, latesttransaction.from)}
                                                    {/* Copy icon for "from" address */}
                                                    <span
                                                        className="latesttransaction-copy-icon"
                                                        onClick={() => copyToClipboard(latesttransaction.from)}
                                                    >
                                                        &#x2398;
                                                    </span>
                                                </div>
                                                <div className="latesttransaction-receiver"
                                                    onClick={() => handleAlladdressClick(latesttransaction.to)}
                                                    style={{ cursor: "pointer" }}>
                                                    <img
                                                        src={addresspng1}
                                                        alt="To"
                                                        className="latesttransaction-address-icon-alt"
                                                    />
                                                    {shortenAddress(6, latesttransaction.to)}
                                                    {/* Copy icon for "to" address */}
                                                    <span
                                                        className="latesttransaction-copy-icon"
                                                        onClick={() => copyToClipboard(latesttransaction.to)}
                                                    >
                                                        &#x2398;
                                                    </span>
                                                </div>
                                            </td>

                                            {/* Column 3: Time and status */}
                                            <td>
                                                <div className="latesttransaction-status success">
                                                    Success
                                                </div>
                                                <div >
                                                    <div className='latesttransaction-status SendReceived'> {walletData?.address === latesttransaction.from ? 'Send' : 'Received'}</div>

                                                </div>

                                            </td>
                                            {/* Column 4: Value and Fee */}
                                            <td>
                                                <div className="latesttransaction-value">
                                                    {latesttransaction.value} Value
                                                </div>
                                                <div className="latesttransaction-fee">
                                                    {latesttransaction.fee} Fee
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        </div>
                    )}
                </div>

            )}
            <SendModal show={showtokenModal} handleClose={handleClose} handleTokenSend={handleTokenSend} />

        </div>
    );
};

export default Dashboard;
