import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col } from "react-bootstrap";
import success from "../images/success.svg";
import transactionlogo from "../images/transaction.png";
import Block from "../images/Block.png";
import arrow from "../images/arrow.png";
import addresspng from "../images/address.png";
import addresspng1 from "../images/address1.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tokenchartdatahome from './Charts/Tokenchartdatahome';
import { io } from "socket.io-client";
const socket = io("https://socket.rubyexplorer.com"); // Change to your WebSocket server URL
const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const navigate = useNavigate();

 const [timeAgo, setTimeAgo] = useState({});

  useEffect(() => {
    socket.emit("subscribe");
    socket.on("Transaction-update", (data) => {
      setTransactions(data.Tokentransaction);

      // Set initial timeAgo when transactions are received
      const initialTimeAgo = {};
      data.TransactionHistories.forEach(transaction => {
        initialTimeAgo[transaction.hash] = formatTimeAgo(transaction.createdAt);
      });
      setTimeAgo(initialTimeAgo);
    });

    return () => {
      socket.off("Transaction-update");
    };
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(prevTimeAgo => {
        const updatedTimeAgo = {};
        transactions.forEach(transaction => {
          updatedTimeAgo[transaction.hash] = formatTimeAgo(transaction.createdAt);
        });
        return updatedTimeAgo;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [transactions]);


  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-6)}`;
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };

  const formatTimeAgoblock = (timestamp) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let times = currentTime - timestamp;
    return `${times}s ago`;
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    toast.success("copied to clipboard!");
  };

  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };

  const handleallTransactionClick = () => {
    navigate(`/Alltransactions`);
  };



  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };

  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  return (
    <>
      <div>

        <Row className="transaction-container">
          <Col className="Tokenchartdatahome" md={5}>
            <Tokenchartdatahome />
          </Col>
          <Col md={7}>
          <h4 className="latesttransaction-table-title">
            Token Transactions
          </h4>
          <div className="latesttransaction-table-body">
            <Table>
              <tbody>
                {transactions.map((latesttransaction, index) => (
                  <tr key={index}>
                    {/* Column 1: Transaction type and hash */}
                    <td>
                      <div className="latesttransaction-category">
                        <span>
                        {latesttransaction.transctiontype === 0
                          ? "Coin Transfer"
                          : latesttransaction.transctiontype === 1
                          ? "Contract Create"
                          : latesttransaction.transctiontype === 2
                          ? "Token Transfer"
                          : "Contract Call"}
                          </span>
                          <span className="latesttransaction-time">
                        {formatTimeAgo(latesttransaction.createdAt)}
                       
                      </span>
                      </div>
                    
                      <div className="latesttransaction-sender" 
                       onClick={() => handleTransactionClick(latesttransaction.hash)}
                       style={{ cursor: "pointer" }}>
                        <img
                          src={transactionlogo}
                          alt="From"
                          className="latesttransaction-address-icon"
                        />
                        {shortenAddress(latesttransaction.hash)}
                        {/* Copy icon for "from" address */}
                        <span
                          className="latesttransaction-copy-icon"
                          onClick={() => copyToClipboard(latesttransaction.hash)}
                        >
                          &#x2398;
                        </span>
                      </div>
                  
                     
                    </td>

                    {/* Column 2: Sender (from) and Receiver (to) */}
                    <td>
                      <div className="latesttransaction-sender"
                       onClick={() => handleAlladdressClick(latesttransaction.from)}
                       style={{ cursor: "pointer" }}>
                        <img
                          src={addresspng}
                          alt="From"
                          className="latesttransaction-address-icon"
                        />
                        {shortenAddress(latesttransaction.from)}
                        {/* Copy icon for "from" address */}
                        <span
                          className="latesttransaction-copy-icon"
                          onClick={() => copyToClipboard(latesttransaction.from)}
                        >
                          &#x2398;
                        </span>
                      </div>
                      <div className="latesttransaction-receiver"
                       onClick={() => handleAlladdressClick(latesttransaction.to)}
                       style={{ cursor: "pointer" }}>
                        <img
                          src={addresspng1}
                          alt="To"
                          className="latesttransaction-address-icon-alt"
                        />
                        {shortenAddress(latesttransaction.to)}
                        {/* Copy icon for "to" address */}
                        <span
                          className="latesttransaction-copy-icon"
                          onClick={() => copyToClipboard(latesttransaction.to)}
                        >
                          &#x2398;
                        </span>
                      </div>
                    </td>

                    {/* Column 3: Time and status */}
                    <td>
                        <div className="latesttransaction-status success">
                          Success
                      </div>
                    </td>

                    {/* Column 4: Value and Fee */}
                    <td>
                      <div className="latesttransaction-value">
                      {(latesttransaction.value).toFixed(5)} Value
                      </div>
                      <div className="latesttransaction-fee">
                      {Number(latesttransaction.fee).toFixed(5)} Fee
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div
              className="latesttransaction-view-all"
              onClick={handleallTransactionClick}
            >
              View Token Transactions
            </div>
          </div>
        </Col>
          <Col  md={5}className="Tokenchartdatahomemobile">
          <Tokenchartdatahome />
        </Col>
        </Row>
      

      </div>

    </>
  );
};

export default TransactionTable;
