// src/HomePage.js
import React, { useState, useEffect } from 'react';
import '../Styles/GridPage.css'; // Import your CSS file for styling
import Block from '../images/Block.png'; // Make sure to update the path to your logo
import backintime from '../images/back-in-time.png'; // Make sure to update the path to your logo
import transactionlogo from '../images/transaction.png'; // Make sure to update the path to your logo
import walletlogo from '../images/wallet.svg'; // Make sure to update the path to your logo
import gasoline from '../images/gasoline.png'; // Make sure to update the path to your logo
import { io } from "socket.io-client";

const socket = io("https://socket.rubyexplorer.com"); // Change to your WebSocket server URL
const GridPage = () => {
  const [data, setData] = useState({});
  const [getgriddata, setgriddata] = useState({});

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://rubyexplorer.com/getHomepagedata');
  //       const result = await response.json();
  //       setData(result.result);
  //     } catch (error) {
  //       console.error('Error fetching homepage data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

      useEffect(() => {
          // Connect to WebSocket and request block data
          socket.emit("gethomedata");
  
          // Listen for block updates
          socket.on("getgethomedata-update", (data) => {
              setgriddata(data.blockandtrncount[0]);
              setData(data.blockandtrncount[0]);

          });
  
          // Cleanup listener on unmount
          return () => {
              socket.off("getgethomedata-update");
          };
      }, []);
  let styles={width: '25px', height: '25px', marginRight: '20px',marginTop:'10px'  };

  return (

    <div className="grid-container">

      <div className="grid-item">
        <div className="grid-title">
          <img src={Block} alt="Block logo" style={styles} />
          <span className='grid-title-text'>Total blocks</span>
        </div>
        <div className='grid-data'>{getgriddata.latestBlockNumber}</div>
      </div>


      <div className="grid-item">
        <div className="grid-title">
          <img src={backintime} alt="backintime logo" className='gridstyles'style={styles} />
          
          <span className='grid-title-text'>Avg. block time</span>
        </div>
        <div className='grid-data'>10 s</div>
      </div>


      <div className="grid-item">
        <div className="grid-title">
          <img src={transactionlogo} alt="transactionlogo logo" style={styles} />
          <span className='grid-title-text'>Total Transaction</span>
        </div>
        <div className='grid-data'>{getgriddata.trx_length}</div>
      </div>

      <div className="grid-item">
        <div className="grid-title">
          <img src={walletlogo} alt="walletlogo logo" style={styles} />
          <span className='grid-title-text'>Wallet addresses</span>
        </div>
        <div className='grid-data'>{data.totaladdress}</div>
      </div>

      <div className="grid-item">
        <div className="grid-title">
          <img src={gasoline} alt="gasoline logo" style={styles} />
          <span className='grid-title-text'>Gas tracker</span>
        </div>
        <div className="grid-data">
  {data?.fee ? `${data.fee.toFixed(5)} Ruby` : '0 Ruby'}
</div>
      </div>


    </div>

  );
};

export default GridPage;
