import Card from './Card';
import GridPage from './GridPage';
import TransactionGraph from './TransactionGraph';
import TransactionTable from './TransactionTable';
import Homepageblock from './Homepageblock';
import Contractdeploy from './Contractdeploy';
import TokenContractdeploy from './TokenContractdeploy';
import ContractTransactionTable from './ContractTransactionTable';
import TokenTransactionTable from './TokenTransactionTable';
import BlockTablehome from './blockTablehome';
import { useEffect } from "react";


export default function Homepage() {

    useEffect(() => {
        const handleVisibilityChange = () => {
          if (document.visibilityState === "visible") {
            window.location.reload(); // Reload the page when user returns
          }
        };
    
        document.addEventListener("visibilitychange", handleVisibilityChange);
    
        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
      }, []);
    return (
        <div>
            <Card />
            <GridPage />
            <TransactionGraph />
            <Homepageblock />
            <TransactionTable />
            <BlockTablehome />
            <TokenTransactionTable />
            <ContractTransactionTable />
            <TokenContractdeploy />
            <Contractdeploy />
        </div>
    );
}
