import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col } from "react-bootstrap";
import success from "../images/success.svg";
import latesttransactionIcon from "../images/transaction.png";
import blockIcon from "../images/Block.png";
import arrowIcon from "../images/arrow.png";
import addressIcon from "../images/address.png";
import addressIconAlt from "../images/address1.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TransactionsPerHour from "./Charts/TransactionsPerHour";
import "../Styles/latestTransactionTable.css";
import { io } from "socket.io-client";

const socket = io("https://socket.rubyexplorer.com");

const LatesttransactionTable = () => {
  const [latesttransactions, setlatesttransactions] = useState([]);
  const navigate = useNavigate();
  const [timeAgo, setTimeAgo] = useState({});

  useEffect(() => {
    socket.emit("subscribe");
    socket.on("Transaction-update", (data) => {
      setlatesttransactions(data.TransactionHistories);
    });
    return () => {
      socket.off("Transaction-update");
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo((prevTimeAgo) => {
        const updatedTimeAgo = {};
        latesttransactions.forEach((block) => {
          updatedTimeAgo[block.blockNumber] = formatTimeAgo(block.timestamp);
        });
        return updatedTimeAgo;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [latesttransactions]);
  const formatTimeAgo = (date) => {
    const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    if (seconds < 60) return `${seconds}s ago`;
    if (seconds < 3600) return `${Math.floor(seconds / 60)}m ago`;
    if (seconds < 86400) return `${Math.floor(seconds / 3600)}h ago`;
    return `${Math.floor(seconds / 86400)}d ago`;
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    toast.success("Copied to clipboard!");
  };

  const handlelatesttransactionClick = (hash) =>
    navigate(`/transaction/${hash}`);

  const handleAlllatesttransactionsClick = () => navigate(`/Alltransactions`);

  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };
  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}......${address.slice(-4)}`;
  };

  return (
    <div>
      <Row className="latesttransaction-table-container">
        <Col md={7}>
          <h4 className="latesttransaction-table-title">
            Latest Transactions
          </h4>
          <div className="latesttransaction-table-body">
            <Table>
              <tbody>
                {latesttransactions.map((latesttransaction, index) => (
                  <tr key={index}>
                    {/* Column 1: Transaction type and hash */}
                    <td>
                      <div className="latesttransaction-category">
                        <span>
                          {latesttransaction.transctiontype === 0
                            ? "Coin Transfer"
                            : latesttransaction.transctiontype === 1
                              ? "Contract Create"
                              : latesttransaction.transctiontype === 2
                                ? "Token Transfer"
                                : "Contract Call"}
                        </span>
                        <span className="latesttransaction-time">
                          {formatTimeAgo(latesttransaction.createdAt)}

                        </span>
                      </div>

                      <div
                        className="latesttransaction-sender"
                        onClick={() => handlelatesttransactionClick(latesttransaction.hash)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={latesttransactionIcon}
                          alt="From"
                          className="latesttransaction-address-icon"
                        />
                        {shortenAddress(latesttransaction.hash)}
                        {/* Copy icon for "from" address */}
                        <span
                          className="latesttransaction-copy-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(latesttransaction.hash);
                          }}
                        >
                          &#x2398;
                        </span>
                      </div>



                    </td>

                    {/* Column 2: Sender (from) and Receiver (to) */}
                    <td>
                      <div className="latesttransaction-sender"
                       onClick={() => handleAlladdressClick(latesttransaction.from)}
                       style={{ cursor: "pointer" }}>
                        <img
                          src={addressIcon}
                          alt="From"
                          className="latesttransaction-address-icon"
                        />
                        {shortenAddress(latesttransaction.from)}
                        {/* Copy icon for "from" address */}
                        <span
                          className="latesttransaction-copy-icon"
                          onClick={() => copyToClipboard(latesttransaction.from)}
                        >
                          &#x2398;
                        </span>
                      </div>
                      <div className="latesttransaction-receiver"
                       onClick={() => handleAlladdressClick(latesttransaction.to)}
                       style={{ cursor: "pointer" }}>
                        <img
                          src={addressIconAlt}
                          alt="To"
                          className="latesttransaction-address-icon-alt"
                        />
                        {shortenAddress(latesttransaction.to)}
                        {/* Copy icon for "to" address */}
                        <span
                          className="latesttransaction-copy-icon"
                          onClick={() => copyToClipboard(latesttransaction.to)}
                        >
                          &#x2398;
                        </span>
                      </div>
                    </td>

                    {/* Column 3: Time and status */}
                    <td>
                      <div className="latesttransaction-status success">
                        Success
                      </div>
                    </td>

                    {/* Column 4: Value and Fee */}
                    <td>
                      <div className="latesttransaction-value">
                        {(latesttransaction.value).toFixed(5)} Value
                      </div>
                      <div className="latesttransaction-fee">
                        {Number(latesttransaction.fee).toFixed(5)
                        } Fee
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div
              className="latesttransaction-view-all"
              onClick={handleAlllatesttransactionsClick}
            >
              View all Transactions
            </div>
          </div>
        </Col>
        <Col md={5}>
          <TransactionsPerHour />
        </Col>
      </Row>
    </div>
  );
};

export default LatesttransactionTable;
