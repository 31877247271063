import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../Styles/TransactionDetail.css"; // Import your CSS file for styling
import success from "../images/success.svg";
import flash from "../images/flash.png"; // Make sure to update the path to your logo
import addresspng1 from "../images/address1.png";
import info from "../images/info.png";
import balancelogo from "../images/balancelogo.png";
import Search from "./Searchbar";
import smartcontract from "../images/smart-contract.png";
import tokenlogo from "../images/tokenslogo.png";
import { Table, Row, Col } from "react-bootstrap";
import addresspng from "../images/address1.png";
import arrow from "../images/arrow.png";
import transactionlogo from "../images/transaction.png";

const TokenDetail = () => {
  const { contractAddress } = useParams();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState(null);
  const [activeTab, setActiveTab] = useState("Token transfers");
  const [traceData, setTraceData] = useState(null);
  const [Internaltransactions, setInternalTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await fetch(
          `https://rubyexplorer.com/getTokenData/${contractAddress}`
        );
        const data = await response.json();
        setTransactionData(data.result);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchTransactionData();
  }, [contractAddress]);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-4)}`;
  };
  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://rubyexplorer.com/getTokenTransction/${contractAddress}/1/200`
        );
        const data = await response.json();

        setInternalTransactions(data.result ? data.result : []); // Corrected to map the array properly
      } catch (error) {
        console.error("Error fetching all transactions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [contractAddress]);
  if (!transactionData) {
    return <div>Loading...</div>;
  }
  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };
  const handlecontractDetailClick = (contractAddress) => {
    navigate(`/TokenDetail/${contractAddress}`);
  };
  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };
  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };
  return (
    <div className="transaction-detail-container">
      <Search />
      <div className="addressheading">Token details</div>

      <div className="alladdress">
        <img
          src={smartcontract}
          alt="addresspng logo"
          className="alladdresslogo"
        />
        {transactionData.contractAddress}
      </div>
      <div className="alladdress1">
        <img
          src={smartcontract}
          alt="addresspng logo"
          className="alladdresslogo"
        />
        {shortenAddress(20, transactionData.contractAddress)}
      </div>
      <div className="transaction-detail">
        <span className="key">
          {" "}
          <img src={info} alt="info logo" className="infologo" />
          name
        </span>
        <span className="text-value" style={{ color: "#101112CC" }}>
          {" "}
          <img src={tokenlogo} alt="info logo" className="infologo" />
          {transactionData.name}
        </span>

        <span className="key">
          {" "}
          <img src={info} alt="miner logo" className="infologo" />
          Symbol
        </span>
        <span className="text-value">{transactionData.symbol}</span>
        <span className="key">
          {" "}
          <img src={info} alt="info logo" className="infologo" />
          Balance
        </span>
        <span className="text-value" style={{ color: "#101112CC" }}>
          {" "}
          <img src={balancelogo} alt="info logo" className="infologo" />
          {transactionData.rubybalance} RUBY
        </span>
        <span className="key">
          {" "}
          <img src={info} alt="miner logo" className="infologo" />
          Total Supply{" "}
        </span>
        <span className="text-value">{transactionData.totalSupply}</span>

        <span className="key">
          {" "}
          <img src={info} alt="miner logo" className="infologo" />
          Creator
        </span>
        <span className="text-value">
          <img
            src={addresspng1}
            alt="info logo"
            className="infologo"
            onClick={() => handleAlladdressClick(transactionData.owner)}
            style={{ cursor: "pointer" }}
          />
          {shortenAddress(20, transactionData.owner)}
        </span>

        <span className="key">
          {" "}
          <img src={info} alt="miner logo" className="infologo" />
          Transactions
        </span>
        <span className="text-value">0</span>

        <span className="key">
          {" "}
          <img src={info} alt="miner logo" className="infologo" />
          Transfers
        </span>
        <span className="text-value">0</span>
      </div>

      <div className="transaction-detail">
        <div className="token-tabs">
          {["Token transfers", "Internal txns", "Contract"].map((tab) => (
            <button
              key={tab}
              className={`transaction-button ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        {/* Add similar conditional rendering blocks for each tab's content */}
        {activeTab === "Token transfers" && (
          <div>
            <div className="Alltransaction-desktop">
              <Table>
                <tbody>
                  {Internaltransactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>
                        <div
                          className="Alltransaction-hash"
                          onClick={() =>
                            handleTransactionClick(transaction.hash)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {shortenAddress(12, transaction.hash)}
                        </div>
                        <div className="Alltransaction-time">
                          {formatTimeAgo(transaction.createdAt)}
                        </div>
                      </td>
                      <td>
                        <div className="Alltransaction-type">
                          {/* <div className='Alltransaction-status'>
                         coin transfer
                       </div> */}
                          <div className="Alltransaction-status1">success</div>
                        </div>
                      </td>
                      <td>
                        <div className="Alltransaction-twoaddress">
                          <img
                            src={addresspng}
                            alt="Transaction logo"
                            className="Alltransaction-address-logo"
                          />{" "}
                          <div className="Alltransaction-address">
                            <span
                              onClick={() =>
                                handleAlladdressClick(transaction.from)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {shortenAddress(5, transaction.from)}
                            </span>
                            <span
                              onClick={() => copyToClipboard(transaction.from)}
                              className="copy-icon"
                              title="Copy From"
                            >
                              &#x2398;
                            </span>
                          </div>
                          <img
                            src={addresspng1}
                            alt="Transaction logo"
                            className="Alltransaction-address-logo1"
                          />{" "}
                          <div className="Alltransaction-address1">
                            <span
                              onClick={() =>
                                handleAlladdressClick(
                                  transaction?.to === "0"
                                    ? transaction.contract
                                    : transaction.to
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {shortenAddress(
                                5,
                                transaction?.to === "0"
                                  ? transaction.contract
                                  : transaction.to
                              )}
                            </span>
                            <span
                              onClick={() =>
                                copyToClipboard(
                                  transaction?.to == "0"
                                    ? transaction.contract
                                    : transaction.to
                                )
                              }
                              className="copy-icon"
                              title="Copy To"
                            >
                              &#x2398;
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="block-number">
                          {transaction.blockNumber}
                        </div>
                      </td>

                      <td>
                        <div className="Alltransaction-transfer">
                          {transaction?.transctiontype === 0
                            ? "Coin transfer"
                            : transaction?.transctiontype === 1
                            ? "Contract Create"
                            : transaction?.transctiontype === 2
                            ? "Token transfer"
                            : "Unknown"}
                        </div>
                      </td>
                      <td>
                        <div className="Alltransaction-amount-fee">
                          {" "}
                          <div className="Alltransaction-amount">
                            {trimValue(transaction.value)}
                            <span className="Alltransaction-unit"> Value</span>
                          </div>
                          <div className="Alltransaction-fee">
                            {trimValue(transaction.fee)}
                            <span className="Alltransaction-unit"> Fee</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="Alltransaction-cards1">
              <Table table>
                <tbody>
                  {Internaltransactions.map(
                    (transaction, index) => (
                      (
                        <tr
                          key={transaction.address}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            <div>
                              <img
                                src={transactionlogo}
                                alt="block logo"
                                className="Topaccounts-address-logo-mobile"
                              />
                              <div
                                className="Topaccounts-address-mobile"
                                onClick={() =>
                                  handleTransactionClick(transaction.hash)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {shortenAddress(15, transaction.hash)}
                              </div>
                              <div className="Topaccounts-time">
                                {formatTimeAgo(transaction.createdAt)}
                              </div>
                              <img
                                src={addresspng}
                                alt="block logo"
                                className="Topaccounts-address-logo-mobile"
                              />
                              <div
                                className="Topaccounts-address-mobile"
                                onClick={() =>
                                  handleAlladdressClick(transaction.from)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {shortenAddress(5, transaction.from)}
                              </div>
                              <img
                                src={arrow}
                                alt="Transaction logo"
                                className="Topaccounts-arror-logo1"
                              />{" "}
                              <img
                                src={addresspng1}
                                alt="block logo"
                                className="Topaccounts-address1-logo-mobile"
                              />
                              <div
                                className="Topaccounts-address1-mobile"
                                onClick={() =>
                                  handleAlladdressClick(transaction.to)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {shortenAddress(
                                  5,
                                  transaction?.to === "0"
                                    ? transaction.contract
                                    : transaction.to
                                )}
                              </div>
                              <br />
                              <div className="Topaccounts-data-mobile">
                                Value {transaction.value}
                              </div>
                              <br />
                              <div className="Topaccounts-time">
                                {transaction?.transctiontype === 0
                                  ? "Coin transfer"
                                  : transaction?.transctiontype === 1
                                  ? "Contract Create"
                                  : transaction?.transctiontype === 2
                                  ? "Token transfer"
                                  : "Contract call"}
                              </div>
                              <div className="Topaccounts-data-mobile">
                                Fee {transaction.fee}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {activeTab === "Contract" && transactionData.abi && (
          <div>
            <div className="tokencode">Contract Abi</div>{" "}
            <div className="trace-content">
              {JSON.stringify(transactionData.abi, null, 2)}
            </div>
            <div className="tokencode">Contract Code</div>
            <div className="trace-content">
              <span
                dangerouslySetInnerHTML={{
                  __html: transactionData.contractcode,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenDetail;
