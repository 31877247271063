import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { io } from "socket.io-client";

const socket = io("https://socket.rubyexplorer.com");

const TransactionTable = () => {
  const [blocks, setBlocks] = useState([]);
  const [timeAgo, setTimeAgo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    socket.emit("getblock");
    socket.on("getblock-update", (data) => {
      setBlocks(data.blockdata);
    });

    return () => {
      socket.off("getblock-update");
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo((prevTimeAgo) => {
        const updatedTimeAgo = {};
        blocks.forEach((block) => {
          updatedTimeAgo[block.blockNumber] = formatTimeAgoblock(block.timestamp);
        });
        return updatedTimeAgo;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [blocks]);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-6)}`;
  };

  const formatTimeAgoblock = (timestamp) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let timeDiff = currentTime - timestamp;

    if (timeDiff < 60) return `${timeDiff}s ago`;
    if (timeDiff < 3600) return `${Math.floor(timeDiff / 60)}m ago`;
    if (timeDiff < 86400) return `${Math.floor(timeDiff / 3600)}h ago`;
    return `${Math.floor(timeDiff / 86400)}d ago`;
  };

  const handleBlockClick = (blockNumber) => {
    navigate(`/Block/${blockNumber}`);
  };

  return (
    <div style={{ padding: "10px" }}>
      <h4 style={{ fontWeight: "bold", marginBottom: "15px" }}>Latest Blocks</h4>

      {/* Horizontal Grid Layout */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "20px",
          padding: "10px",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        {blocks.map((block) => (
          <div
            key={block.blockNumber}
            style={{
              background: "rgba(28, 120, 137, 0.1)",
              padding: "15px",
              borderRadius: "10px",
              boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              cursor: "pointer",
              minWidth: "220px",
              transition: "transform 0.2s ease-in-out",
            }}
            onClick={() => handleBlockClick(block.blockNumber)}
          >
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "16px" }}>
              Block #{block.blockNumber}
            </span>

            {/* Timestamp and Transaction Count on Same Row */}
            <div style={{ display: "flex", justifyContent: "center", gap: "10px", fontSize: "14px", color: "gray", marginTop: "5px" }}>
              <span>{timeAgo[block.blockNumber] || formatTimeAgoblock(block.timestamp)}</span>
              <span>|</span>
              <span>Txn: {block.Trx}</span>
            </div>

            {/* Validator Address */}
            <div style={{ fontSize: "13px", fontWeight: "bold", color: "#007bff", marginTop: "5px" }}>
              Validator: {shortenAddress(6, block.blockHash)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionTable;
