import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Spinner, Button } from "react-bootstrap";
import "../Styles/Alltransactions.css";
import "../Styles/Topaccounts.css";
import addresspng1 from "../images/address1.png";
import smartcontract from "../images/smart-contract.png";
import Search from "./Searchbar";

const VerifiedRuby = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [pagenumber, setPagenumber] = useState(1);
  const [totaltoken, setTotaltoken] = useState(0);
  const pagelength = 20; // Default page length

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://rubyexplorer.com/getTokens/${pagenumber}/${pagelength}`);
        const data = await response.json();

        if (data.result) {
          setTransactions(data.result);
          setTotaltoken(data.totalPages * pagelength); // Ensures correct total count
        } else {
          setTransactions([]);
          setTotaltoken(0);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [pagenumber]);

  const shortenAddress = (length, address) => `${address.slice(0, length)}......${address.slice(-4)}`;

  const handleAddressClick = (address) => navigate(`/address/${address}`);

  const handleContractDetailClick = (contractAddress) => navigate(`/TokenDetail/${contractAddress}`);

  const formatTimeAgo = (date) => {
    const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    if (seconds < 60) return `${seconds}s ago`;
    if (seconds < 3600) return `${Math.floor(seconds / 60)}m ago`;
    if (seconds < 86400) return `${Math.floor(seconds / 3600)}h ago`;
    return `${Math.floor(seconds / 86400)}d ago`;
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(totaltoken / pagelength)) {
      setPagenumber(newPage);
    }
  };

  return (
    <div className="Alltransaction-detail-container">
      <Search />
      <div className="Topaccounts-heading">Verified Ruby Tokens</div>
      {loading ? (
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="Alltransaction-grid1">
            <div className="Alltransaction-grid-item">
              <div className="Alltransaction-grid-title">Total Tokens</div>
              <div className="Alltransaction-grid-value-container">
                <div className="Alltransaction-grid-value">{totaltoken}</div>
                <div className="Alltransaction-grid-subtitle">(Total)</div>
              </div>
            </div>
            <div className="Alltransaction-grid-item">
              <div className="Alltransaction-grid-title">Verified Tokens</div>
              <div className="Alltransaction-grid-value-container">
                <div className="Alltransaction-grid-value">{totaltoken}</div>
                <div className="Alltransaction-grid-subtitle">(Total)</div>
              </div>
            </div>
          </div>

          <div className="Topaccounts-detail">
            <Table>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>
                      <div className="Topaccounts-index">
                        {(pagenumber - 1) * pagelength + index + 1}
                      </div>
                    </td>
                    <td>
                      <img src={smartcontract} alt="Transaction logo" className="Topaccounts-address-logo1" />
                    </td>
                    <td>
                      <div
                        className="Topaccounts-balance"
                        onClick={() => handleContractDetailClick(transaction.contract_address)}
                        style={{ cursor: "pointer" }}
                      >
                        {transaction.contract_address}
                      </div>
                    </td>
                    <td>
                      <img src={addresspng1} alt="Transaction logo" className="Topaccounts-address-logo1" />
                    </td>
                    <td>
                      <div
                        className="Topaccounts-balance"
                        onClick={() => handleAddressClick(transaction.owneraddress)}
                        style={{ cursor: "pointer" }}
                      >
                        {transaction.owneraddress}
                      </div>
                    </td>
                    <td>
                      <div className="Topaccounts-Percentage">{formatTimeAgo(transaction.createdAt)}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="Topaccounts-cards">
            <Table>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>
                      <div>
                        <img src={smartcontract} alt="block logo" className="Topaccounts-address-logo-mobile" />
                        <div
                          className="Topaccounts-address-mobile"
                          onClick={() => handleContractDetailClick(transaction.contract_address)}
                          style={{ cursor: "pointer" }}
                        >
                          {transaction.contract_address}
                        </div>
                        <br />
                        <img src={addresspng1} alt="Transaction logo" className="Topaccounts-address-logo1" />
                        <div
                          className="Topaccounts-address-mobile"
                          onClick={() => handleAddressClick(transaction.owneraddress)}
                          style={{ cursor: "pointer" }}
                        >
                          Owner {shortenAddress(20, transaction.owneraddress)}
                        </div>
                        <br />
                        <div className="Topaccounts-data-mobile">Time {formatTimeAgo(transaction.createdAt)}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="pagination-controls">
            <Button
              variant="primary"
              disabled={pagenumber === 1}
              onClick={() => handlePageChange(pagenumber - 1)}
              style={{ padding: "5px 10px", fontSize: "12px", borderRadius: "4px" }}
            >
              Previous
            </Button>
            <span className="pagination-info">
              Page {pagenumber} of {Math.ceil(totaltoken / pagelength)}
            </span>
            <Button
              variant="primary"
              disabled={pagenumber === Math.ceil(totaltoken / pagelength)}
              onClick={() => handlePageChange(pagenumber + 1)}
              style={{ padding: "5px 10px", fontSize: "12px", borderRadius: "4px" }}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default VerifiedRuby;
