import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col } from "react-bootstrap";
import Block from "../images/Block.png";
import "react-toastify/dist/ReactToastify.css";
import BlockDatachart from './Charts/blockDatacharthome';
import { io } from "socket.io-client";
import "../Styles/blockTablehome.css";

const socket = io("https://socket.rubyexplorer.com"); // Change to your WebSocket server URL

const TransactionTable = () => {
  const [blocks, setBlocks] = useState([]);
  const navigate = useNavigate();
    const [timeAgo, setTimeAgo] = useState({});
  
  useEffect(() => {
    socket.emit("getblock");
    socket.on("getblock-update", (data) => {
      setBlocks(data.blockdata);
    });
    return () => {
      socket.off("getblock-update");
    };
  }, []);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-6)}`;
  };

    useEffect(() => {
      const interval = setInterval(() => {
        setTimeAgo((prevTimeAgo) => {
          const updatedTimeAgo = {};
          blocks.forEach((block) => {
            updatedTimeAgo[block.blockNumber] = formatTimeAgoblock(block.timestamp);
          });
          return updatedTimeAgo;
        });
      }, 1000);
  
      return () => clearInterval(interval);
    }, [blocks]);


  const formatTimeAgoblock = (timestamp) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let times = currentTime - timestamp;
    return `${times}s ago`;
  };

 

  const handleBlockClick = (blockNumber) => {
    navigate(`/Block/${blockNumber}`);
  };

  const handleAllBlockClick = () => {
    navigate(`/AllBlock`);
  };



  return (
    <>
      <div>

        <Row className="transaction-container">

          <Col md={4}>
            <div className="block-cards">
              <h4 className="headingBlocks">Latest Blocks</h4>
              <Table table>
                <tbody>
                  {blocks.slice(0,4).map((block) => (
                    <tr key={block.blockNumber} onClick={() => handleBlockClick(block.blockNumber)} style={{ cursor: "pointer" }}>
                      <td>
                        <div>
                          <img src={Block} alt="block logo" className="block-logo" />
                          <span className="block-blockNumber">{block.blockNumber}</span>
                          <br />
                          <div className="block-timestamp">{formatTimeAgoblock(block.timestamp)}</div>
                          <br />
                          <div className="block-Trx">Txn {block.Trx}</div>
                          <br />

                          <div className="blockHash-Validator">Validator: {shortenAddress(6, block.blockHash)}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>

              </Table>

              <div className="buttenallblock" onClick={handleAllBlockClick}>
                View all blocks
              </div>


            </div>
          </Col>

          <Col md={7}>
            <BlockDatachart />
          </Col>
        </Row>


      </div>

    </>
  );
};

export default TransactionTable;
