import React, { useEffect, useState } from "react";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";

import "./Chart.css";

// ✅ Set default chart styles
defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";

const Transactionchartdata = () => {
  const [loading, setLoading] = useState(true);
  const [blockData, setBlockData] = useState([]);

  useEffect(() => {
    fetch("https://rubyexplorer.com/getBlockdatadataforchart")
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setBlockData(data.result);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching block data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="TransactionsandfeePerHour">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>

                <div className="blockDatacharthome-dataCard blockDatacharthome-revenueCard">
            <Bar
              data={{
                labels: blockData.map((data) => data.blockNumber),
                datasets: [
                  {
                    label: "Blocks",
                    data: blockData.map((data) => data.Trx),
                    backgroundColor: "rgba(154, 98, 197, 0.8)",
                    borderRadius: 5,
                  },
                ],
              }}
              options={{
                plugins: {
                  title: {
                    // text: "Block Transaction",
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        return `Txn: ${tooltipItem.raw.toLocaleString()}`; // ✅ Only shows balance
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      display: true, // ✅ Hides X-axis labels (No Address)
                    },
                    grid: {
                      drawTicks: true, // ✅ Removes small ticks on X-axis
                    },
                  },
                  // y: {
                  //   title: {
                  //     display: true,
                  //     text: "Transaction",
                  //   },
                  // },
                },
              }}
            />
          </div>

        </>
      )}
    </div>
  );
};
export default Transactionchartdata;
