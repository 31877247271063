import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/Searchbar.css"; // Import your CSS file for styling

const SearchComponent = () => {
  const [query, setQuery] = useState("");
  const [searchHistory, setSearchHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const history = JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSearchHistory(history);
    setFilteredHistory(history); // Initialize filteredHistory with the full history
  }, []);

  const updateHistory = (newQuery) => {
    let updatedHistory = [newQuery, ...searchHistory.filter(item => item !== newQuery)];
    if (updatedHistory.length > 5) updatedHistory = updatedHistory.slice(0, 5);
    localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));
    setSearchHistory(updatedHistory);
    setFilteredHistory(updatedHistory); // Reset filtered history when updating the main history
  };

  const clearHistory = () => {
    localStorage.removeItem("searchHistory");
    setSearchHistory([]);
    setFilteredHistory([]);
  };

  const removeItem = (item, e) => {
    e.stopPropagation();  // Stop click event from bubbling to parent
    const updatedHistory = searchHistory.filter((i) => i !== item);
    localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));
    setSearchHistory(updatedHistory);
    setFilteredHistory(updatedHistory); // Update filtered history after removal
  };

  const handleSearch = async (searchQuery) => {
    const finalQuery = searchQuery || query;
    if (!finalQuery) return;
    updateHistory(finalQuery);
    setShowHistory(false);

    try {
      const response = await fetch(`https://rubyexplorer.com/searchcontract`);
      const data = await response.json();

      if (!response.ok || !data.result) {
        navigate("/Invalidinput");
        return;
      }

      const filteredData = data.result.find(item => {
        if (finalQuery.length < 20) {
          return item.contractname.toLowerCase() === finalQuery.toLowerCase();
        } else {
          return item.contract_address.toLowerCase() === finalQuery.toLowerCase();
        }
      });

      if (filteredData) {
        const { contractType, contract_address } = filteredData;
        if (contractType.toLowerCase() === "rbc20") {
          navigate(`/TokenDetail/${contract_address}`);
        } else if (contractType.toLowerCase() === "dapp") {
          navigate(`/contractDetail/${contract_address}`);
        } else {
          navigate("/Invalidinput");
        }
        return;
      }

      const secondaryResponse = await fetch(`https://rubyexplorer.com/searchdata/${finalQuery}`);
      if (!secondaryResponse.ok) {
        navigate("/Invalidinput");
        return;
      }

      const secondaryData = await secondaryResponse.json();
      const resultType = (secondaryData.result || "").trim().toLowerCase();
      navigateByResult(resultType, finalQuery);
    } catch (error) {
      navigate("/Invalidinput");
    }
  };

  const navigateByResult = (resultType, finalQuery) => {
    switch (resultType) {
      case "block":
        navigate(`/block/${finalQuery}`);
        break;
      case "transaction":
        navigate(`/transaction/${finalQuery}`);
        break;
      case "alladdress":
        navigate(`/address/${finalQuery}`);
        break;
      default:
        navigate("/Invalidinput");
        break;
    }
  };

  const handleInputChange = async (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);

    if (newQuery) {
      // Only perform search when the query is less than 20 characters
      if (newQuery.length < 20) {
        const response = await fetch(`https://rubyexplorer.com/searchcontract`);
        const data = await response.json();
        if (data.result) {
          const filtered = data.result.filter(item =>
            item.contractname.toLowerCase().includes(newQuery.toLowerCase())
          );
          setFilteredData(filtered); // Set filtered data for real-time suggestions
        }
      } else {
        setFilteredData([]); // Clear filtered data when query length is 20 or more
      }

      // Filter history
      const matchingHistory = searchHistory.filter(item =>
        item.toLowerCase().includes(newQuery.toLowerCase())
      );
      setFilteredHistory(matchingHistory);
    } else {
      setFilteredHistory(searchHistory); // Reset to full history when input is empty
      setFilteredData([]); // Clear filtered data when query is empty
    }
  };

  const handleHistoryClick = (item) => {
    console.log("History item clicked:", item);  // This should log the clicked item
    setQuery(item); // Update query with clicked history item
    setShowHistory(false); // Close the history dropdown
    handleSearch(item); // Perform search with selected history item
  };

  const handleFocus = () => {
    setShowHistory(true);  // Show history when focused
  };

  const handleBlur = () => {
    // Delay hiding the history dropdown to allow clicks
    setTimeout(() => {
      setShowHistory(false);
    }, 200);
  };

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Search by address / txn hash / block / token..."
        value={query}
        onChange={handleInputChange}
        onFocus={handleFocus} // Show history when focused
        onBlur={handleBlur} // Delay hiding dropdown
      />

      {showHistory && filteredHistory.length > 0 && (
        <div className="history-dropdown">
          <div className="history-header">
            <span>Recent</span>
            <button onClick={clearHistory}>Clear all</button>
          </div>
          <ul>
            {filteredHistory.map((item, index) => (
              <li
                key={index}
                onClick={() => handleHistoryClick(item)} // Trigger search when a history item is clicked
              >
                {item}
                <span
                  className="remove-item"
                  onClick={(e) => removeItem(item, e)} // Properly stop propagation when removing item
                >
                  ✕
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {query.length < 20 && filteredData.length > 0 && (
     <div className="history-dropdown">
          <table className="history-header">
            {/* <thead>
              <tr>
                <th>Contract Name</th>
                <th>Contract Address</th>
              </tr>
            </thead> */}
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index} onClick={() => handleSearch(item.contractname)}>
                  <td>{item.contractname}</td>
                  <td>{item.contract_address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


      <button onClick={() => handleSearch(query)}>Search</button>
    </div>
  );
};

export default SearchComponent;
