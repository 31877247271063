import React, { useEffect, useState } from "react";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import "./Chart.css";

// ✅ Set default chart styles
defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";

const Tokenchartdata = () => {
  const [loading, setLoading] = useState(true);
  const [tokenChartData, setTokenChartData] = useState([]);

  // ✅ Fetch and set Token Chart Data
  useEffect(() => {
    fetch("https://rubyexplorer.com/getContractdeploy")
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setTokenChartData(data.result);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching token data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="TransactionsPerHour">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="TransactionsPerHour-content">
          <div className="TransactionsPerHour-dataCard TransactionsPerHour-revenueCard">
            <Bar
              data={{
                labels: tokenChartData.map((data) => data._id), // Use _id (YYYY-MM-DD) as the labels
                datasets: [
                  {
                    label: "Latest Contract",
                    data: tokenChartData.map((data) => data.count), // Count of transactions per day
                    backgroundColor: "rgba(197, 91, 59, 0.8)",
                    borderRadius: 5,
                  },
                ],
              }}
              options={{
                plugins: {
                  title: {
                    display: true,
                    // text: "COntract Deploy Over Last 24 Days",
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        return `Deploy: ${tooltipItem.raw.toLocaleString()}`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      display: true,
                      maxRotation: 45, // Rotate x-axis labels for better visibility
                      minRotation: 30,
                    },
                    grid: {
                      drawTicks: true,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      callback: (value) => value.toLocaleString(), // Format y-axis numbers
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tokenchartdata;
